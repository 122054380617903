import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose, withHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';
import swal from 'sweetalert';
import cn from 'classnames';

import { WORKPLACE_CHAT_URI } from 'config/constants';

import connect from 'react/hoc/connectProxy';
import Button from 'react/generic/button/Button';

import { getUserWorkplaceId } from 'redux/users/actions';
import { ACTIONS } from 'redux/users/constants';
import { selectUserByExternalId, selectUserWorkplaceIdLoading } from 'redux/users/selectors';

import messages from './contact-button.messages';
import classNames from './contactButton.module.scss';

const enhancer = compose(
  connect(
    (state, props) => ({
      user: selectUserByExternalId(state, props.externalId),
      isLoading: selectUserWorkplaceIdLoading(state, props.externalId),
    }),
    {
      getUserWorkplaceIdAction: getUserWorkplaceId,
    },
  ),
  withHandlers({
    onContactTeamMember: ({
      user,
      getUserWorkplaceIdAction,
    }) => async () => {
      let userWorkplaceId = user?.workplaceId;

      if (!userWorkplaceId) {
        const action = await getUserWorkplaceIdAction(user?.externalId, user?.email);

        if (action.type === ACTIONS.GET_USER_WORKPLACE_ID_FAILURE) {
          if (action.error?.status === 404) {
            swal(
              'User not found',
              'We didn\'t found the person you are trying to contact, this person may not be signed up to Workplace, or the external service may be unavailable. Please try by mail.',
              'info',
            );
          } else {
            swal(
              'User not found',
              'Something went wrong. Please try later',
              'info',
            );
          }
          return;
        }
        userWorkplaceId = action.workplaceId;
      }
      window.open(`${WORKPLACE_CHAT_URI}/${userWorkplaceId}`, '_blank');
    },
  }),
  memo,
);

const ContactButton = ({ onContactTeamMember, isLoading, theme }) => (
  <Button
    outline
    overrideClassName={cn(
      classNames.contactButton,
      classNames[theme],
    )}
    overrideClassNames={classNames}
    disabled={isLoading}
    onClick={onContactTeamMember}
  >
    <FormattedMessage {...messages.CONTACT} />
  </Button>
);

ContactButton.propTypes = {
  onContactTeamMember: PropTypes.func,
  isLoading: PropTypes.bool,
  theme: PropTypes.string,
};

ContactButton.defaultProps = {
  onContactTeamMember: noop,
  isLoading: false,
  theme: '',
};

export default enhancer(ContactButton);
