import { compose, withPropsOnChange } from 'recompose';

import connect from 'react/hoc/connectProxy';

import withDialogState from 'react/generic/dialog/withDialogState';

import { selectProductsMap } from 'redux/products/selectors';

export default compose(
  withDialogState('LocalReferents'),

  connect(
    state => ({
      productsMap: selectProductsMap(state),
    }),
  ),

  withPropsOnChange(
    ['productId', 'businessUnit', 'productsMap'],
    ({ productId, businessUnit, productsMap }) => ({
      localReferents: productId ? productsMap[productId].teamMembers.filter(
        member => member.buReference === businessUnit.id,
      ) : [],
    }),
  ),
);
