import connect from 'react/hoc/connectProxy';
import { compose } from 'recompose';

import withExternalUserLazyLoading from 'react/business/user/lazyLoad/withExternalUserLazyLoading';
import { selectUsersByExternalIds } from 'redux/users/selectors';

export const withTeamLazyLoad = withExternalUserLazyLoading({
  getIdsToLoadFromProps: ({ teamMembers }) => (teamMembers || [])
    .map(({ externalId }) => externalId)
    .filter(Boolean),
});

export const withUsersInfo = connect(
  (state, props) => ({
    users: selectUsersByExternalIds(
      state,
      (props.teamMembers || []).map(({ externalId }) => externalId),
    ),
  }),
);

export default compose(
  withUsersInfo,
  withTeamLazyLoad,
);
