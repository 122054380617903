import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { noop } from 'lodash';
import cn from 'classnames';

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogAction,
} from 'react/generic/dialog/Dialog';
import globalMessages from 'config/global.messages';

import { userShape } from 'shapes/user';
import { teamMemberShape } from 'shapes/product';

import ProductTeamMember from 'react/business/products/details/team/list/ListItem';
import classNamesTeamMembers from 'react/business/products/details/team/list/list.module.scss';
import withUserNames from 'react/business/products/details/team/list/connect/team.connect';

import messages from './local-referents-dialog.messages';

const enhancer = compose(
  withUserNames,

  memo,
);

const LocalReferentsDialog = ({
  isLocalReferentsDialogOpen,
  onCloseLocalReferentsDialog,
  teamMembers,
  users,
}) => (
  <Dialog
    isOpen={isLocalReferentsDialogOpen}
    onRequestClose={onCloseLocalReferentsDialog}
  >
    <DialogTitle>
      <FormattedMessage {...messages.TITLE} />
    </DialogTitle>

    <DialogContent
      className={cn(
        classNamesTeamMembers.container,
        classNamesTeamMembers.dark,
      )}
    >
      <ul>
        {teamMembers.map(referent => (
          <ProductTeamMember
            key={`${referent.externalId}-${referent.role?.id}`}
            users={users}
            teamMember={referent}
            className="light"
          />
        ))}
      </ul>
    </DialogContent>

    <DialogActions>
      <DialogAction onClick={onCloseLocalReferentsDialog}>
        <FormattedMessage {...globalMessages.OK} />
      </DialogAction>
    </DialogActions>
  </Dialog>
);

LocalReferentsDialog.displayName = 'LocalReferentsDialog';

LocalReferentsDialog.propTypes = {
  isLocalReferentsDialogOpen: PropTypes.bool,
  onCloseLocalReferentsDialog: PropTypes.func,
  teamMembers: PropTypes.arrayOf(teamMemberShape),
  users: PropTypes.objectOf(userShape),
};

LocalReferentsDialog.defaultProps = {
  isLocalReferentsDialogOpen: false,
  onCloseLocalReferentsDialog: noop,
  teamMembers: [],
  users: {},
};

export default enhancer(LocalReferentsDialog);
