import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose } from 'recompose';

import IconButton from 'react/generic/button/IconButton';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import classNames from '../icon-button.module.scss';

const enhancer = compose(
  memo,
);

const ContactIconButton = ({ onClickContact }) => (
  <IconButton
    outline
    overrideClassName={classNames.contactButton}
    overrideClassNames={classNames}
    onClick={onClickContact}
  >
    <Asset name={ICON_MAP.phone} />
  </IconButton>
);

ContactIconButton.propTypes = {
  onClickContact: PropTypes.func,
};

ContactIconButton.defaultProps = {
  onClickContact: noop,
};

export default enhancer(ContactIconButton);
