import PropTypes from 'prop-types';
import { cidShape } from './product';

export const versionShape = PropTypes.shape({
  componentId: PropTypes.string,
  createdBy: PropTypes.string,
  creationDate: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  release: PropTypes.string,
  releaseLink: PropTypes.string,
  author: PropTypes.string,
});

export const deploymentShape = PropTypes.shape({
  id: PropTypes.string,
  nickname: PropTypes.string,
  buReference: PropTypes.string,
  version: PropTypes.string,
  modules: PropTypes.arrayOf(PropTypes.string),
  product: PropTypes.string,
  cid: cidShape,
});
