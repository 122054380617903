import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import BrandsPresentation from './filters/brands/Brands.presentation';
import connectBrands from './filters/brands/brands.connect';
import CountriesPresentation from './filters/countries/Countries.presentation';
import connectCountries from './filters/countries/countries.connect';
import withAdvancedFiltersDisplaySwitch from './controls/advancedFilters/withAdvancedFiltersDisplaySwitch';

const enhancer = compose(
  withAdvancedFiltersDisplaySwitch,
  memo,
);

const Brands = connectBrands(BrandsPresentation);
const Countries = connectCountries(CountriesPresentation);

const FacetsContainerBusinessUnits = ({
  filters,
  onSetFilters,
  onResetFilters,
  children,
}) => children({
  commonFilters: [
    <Brands filters={filters} onSetFilters={onSetFilters} />,
    <Countries filters={filters} onSetFilters={onSetFilters} />,
  ],

  onResetFilters,
});

FacetsContainerBusinessUnits.propTypes = {
  filters: PropTypes.shape().isRequired,
  onSetFilters: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
};

FacetsContainerBusinessUnits.displayName = 'FacetsContainerBusinessUnits';

export default enhancer(FacetsContainerBusinessUnits);
