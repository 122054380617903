import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import { businessUnitShape } from 'shapes/businessUnit';

import messages from 'config/business.messages';

import classNames from './businessUnitCardTitle.module.scss';

const enhancer = compose(
  memo,
);

const BusinessUnitCardTitle = ({ businessUnit }) => (
  <div className={classNames.titlesContainer}>
    {
      businessUnit.country ? (
        <div className={classNames.businessUnitCountry}>
          {businessUnit.country.name}
          <img
            alt="Country flag"
            className={classNames.businessCountryFlag}
            src={businessUnit.country.flag?.url}
          />
        </div>
      ) : (
        <div className={classNames.businessUnitCountry}>
          <FormattedMessage {...messages.BUSINESS_UNIT} />
        </div>
      )
    }
    <h2 className={classNames.businessUnitName}>
      {businessUnit.name}
    </h2>
  </div>
);

BusinessUnitCardTitle.propTypes = {
  businessUnit: businessUnitShape.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default enhancer(BusinessUnitCardTitle);
