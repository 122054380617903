import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from 'config/business.messages';

import { moduleShape } from 'shapes/module';

import classNames from './side-components.module.scss';

const enhancer = compose(
  memo,
);

const ModulesListContainer = ({
  modules,
}) => (
  <div className={classNames.moduleContainer}>
    <div className={classNames.moduleTitle}>
      <FormattedMessage {...messages.MODULE} />
      {' :'}
    </div>
    {modules.map(m => m.title).join(', ')}
  </div>
);

ModulesListContainer.displayName = 'ModulesListContainer';

ModulesListContainer.propTypes = {
  modules: PropTypes.arrayOf(moduleShape),
};

ModulesListContainer.defaultProps = {
  modules: [],
};

export default enhancer(ModulesListContainer);
