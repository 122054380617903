import queryString from 'query-string';
import _ from 'lodash';

/**
 * When a value changes, set the new value in the qs.
 *
 * @param {string} key - Changed filter.
 * @param {*} value - New value.
 * @param {string} currentQs - Current query string.
 * @returns {string} The stringified query string.
 */
export const updateQueryString = (key, value, currentQs) => {
  const currentQuery = queryString.parse(currentQs);
  const newQueryString = {
    ...currentQuery,
    [key]: value,
  };
  return queryString.stringify(_.omitBy(newQueryString, _.isNil));
};
