import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { teamMemberShape } from 'shapes/product';

import LocalReferentsDialog from '../dialog/LocalReferentsDialog';

const enhancer = compose(
  memo,
);

const DialogsContainer = ({
  localReferents,
  isLocalReferentsDialogOpen,
  onCloseLocalReferentsDialog,
}) => (
  <>
    { !!localReferents.length && (
      <LocalReferentsDialog
        teamMembers={localReferents}
        isLocalReferentsDialogOpen={isLocalReferentsDialogOpen}
        onCloseLocalReferentsDialog={onCloseLocalReferentsDialog}
      />
    )}
  </>
);

DialogsContainer.displayName = 'DialogsContainer';

DialogsContainer.propTypes = {
  localReferents: PropTypes.arrayOf(teamMemberShape),
  onCloseLocalReferentsDialog: PropTypes.func,
  isLocalReferentsDialogOpen: PropTypes.bool,
};

DialogsContainer.defaultProps = {
  localReferents: [],
  onCloseLocalReferentsDialog: noop,
  isLocalReferentsDialogOpen: false,
};

export default enhancer(DialogsContainer);
