import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';

import EditIconButton from 'react/generic/button/buttons/EditIconButton';
import DeleteIconButton from 'react/generic/button/buttons/DeleteIconButton';
import ContactIconButton from 'react/generic/button/buttons/ContactIconButton';
import { teamMemberShape } from 'shapes/product';

import messages from './side-components.messages';
import classNames from './side-components.module.scss';

const enhancer = compose(
  memo,
);

const ButtonsContainer = ({
  canEditProduct,
  onOpenEditDeploymentDialog,
  onOpenDeleteDeploymentDialog,
  onOpenLocalReferentsDialog,
  localReferents,
}) => (
  <div className={classNames.buttonsContainer}>
    { !!localReferents.length && (
      <div className={classNames.contact}>
        <Tooltip title={<FormattedMessage {...messages.CONTACT_BUTTON_TOOLTIP} />}>
          <div>
            <ContactIconButton
              onClickContact={onOpenLocalReferentsDialog}
              referents={localReferents}
            />
          </div>
        </Tooltip>
      </div>
    )}
    { canEditProduct && (
      <>
        <div className={classNames.edit}>
          <EditIconButton onClickEdit={onOpenEditDeploymentDialog} />
        </div>
        <div className={classNames.delete}>
          <DeleteIconButton onClickDelete={onOpenDeleteDeploymentDialog} />
        </div>
      </>
    )}
  </div>
);

ButtonsContainer.displayName = 'ButtonsContainer';

ButtonsContainer.propTypes = {
  canEditProduct: PropTypes.bool,
  onOpenEditDeploymentDialog: PropTypes.func,
  onOpenDeleteDeploymentDialog: PropTypes.func,
  onOpenLocalReferentsDialog: PropTypes.func,
  localReferents: PropTypes.arrayOf(teamMemberShape),
};

ButtonsContainer.defaultProps = {
  canEditProduct: false,
  onOpenEditDeploymentDialog: noop,
  onOpenDeleteDeploymentDialog: noop,
  onOpenLocalReferentsDialog: noop,
  localReferents: [],
};

export default enhancer(ButtonsContainer);
