import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { noop } from 'lodash';
import { compose, withHandlers, withProps } from 'recompose';

import { businessUnitShape } from 'shapes/businessUnit';

import Card from 'react/generic/card/Card';
import FillPicture from 'react/generic/fillPicture/FillPicture';

import BusinessUnitRibbonSwitch from 'react/business/products/ribbons/BusinessUnitRibbonSwitch';

import { deploymentShape } from 'shapes/deployment';
import { moduleShape } from 'shapes/module';
import { teamMemberShape } from 'shapes/product';
import { versionShape } from 'shapes/version';

import ButtonsContainer from './sideComponents/ButtonsContainer';
import ModulesListContainer from './sideComponents/ModulesListContainer';
import DialogsContainer from './sideComponents/DialogsContainer';

import classNames from './businessUnitCard.module.scss';
import BusinessUnitCardTitle from './BusinessUnitCardTitle';

const enhancer = compose(
  withHandlers({
    onClick: ({ onClick, businessUnit }) => () => {
      onClick(businessUnit.id);
    },
  }),

  withProps(({ localReferents, canEditProduct }) => ({
    shouldDisplayContainer: canEditProduct || !!localReferents.length,
  })),

  memo,
);

const BusinessUnitCard = ({
  canEditProduct,
  onOpenEditDeploymentDialog,
  onOpenDeleteDeploymentDialog,
  onOpenLocalReferentsDialog,
  localReferents,
  modules,
  deployment,
  version,
  isEditDeploymentDialogOpen,
  onCloseEditDeploymentDialog,
  isDeleteDeploymentDialogOpen,
  onCloseDeleteDeploymentDialog,
  isLocalReferentsDialogOpen,
  onCloseLocalReferentsDialog,
  businessUnit,
  isInCharge,
  className,
  onClick,
  shouldDisplayContainer,
  isInactive,
}) => (
  <div className={cn(classNames.container, isInactive ? classNames.inactive : '')}>

    <Card className={cn(classNames.businessUnitCard, className)}>
      <BusinessUnitRibbonSwitch isInCharge={isInCharge} businessUnitId={businessUnit.id} />

      <button
        className={cn(classNames.backgroundButton, 'unstyled with-pointer')}
        onClick={onClick}
      >
        <div className={classNames.main}>
          <div className={classNames.logoContainer}>
            <FillPicture src={businessUnit.logo?.url} className={classNames.logo} />
          </div>
          <div className={classNames.infoContainer}>
            <BusinessUnitCardTitle
              businessUnit={businessUnit}
              onClick={onClick}
            />
          </div>
        </div>

        { !!modules.length && (
          <ModulesListContainer
            modules={modules}
          />
        ) }

      </button>

      { shouldDisplayContainer && (
        <ButtonsContainer
          canEditProduct={canEditProduct}
          onOpenEditDeploymentDialog={onOpenEditDeploymentDialog}
          onOpenDeleteDeploymentDialog={onOpenDeleteDeploymentDialog}
          onOpenLocalReferentsDialog={onOpenLocalReferentsDialog}
          localReferents={localReferents}
        />
      )}
    </Card>

    { shouldDisplayContainer && (
      <DialogsContainer
        localReferents={localReferents}
        canEditProduct={canEditProduct}
        deployment={deployment}
        version={version}
        modules={modules}
        isEditDeploymentDialogOpen={isEditDeploymentDialogOpen}
        onCloseEditDeploymentDialog={onCloseEditDeploymentDialog}
        isDeleteDeploymentDialogOpen={isDeleteDeploymentDialogOpen}
        onCloseDeleteDeploymentDialog={onCloseDeleteDeploymentDialog}
        isLocalReferentsDialogOpen={isLocalReferentsDialogOpen}
        onCloseLocalReferentsDialog={onCloseLocalReferentsDialog}
      />
    ) }
  </div>
);

BusinessUnitCard.propTypes = {
  // ButtonsContainer
  canEditProduct: PropTypes.bool,
  onOpenEditDeploymentDialog: PropTypes.func,
  onOpenDeleteDeploymentDialog: PropTypes.func,
  onOpenLocalReferentsDialog: PropTypes.func,
  localReferents: PropTypes.arrayOf(teamMemberShape),

  // ModulesListContainer
  modules: PropTypes.arrayOf(moduleShape),

  // DialogsContainer
  deployment: deploymentShape,
  version: versionShape,
  onCloseEditDeploymentDialog: PropTypes.func,
  isEditDeploymentDialogOpen: PropTypes.bool,
  onCloseDeleteDeploymentDialog: PropTypes.func,
  isDeleteDeploymentDialogOpen: PropTypes.bool,
  onCloseLocalReferentsDialog: PropTypes.func,
  isLocalReferentsDialogOpen: PropTypes.bool,

  // Card
  businessUnit: businessUnitShape.isRequired,
  isInCharge: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  shouldDisplayContainer: PropTypes.bool,
  isInactive: PropTypes.bool,
};

BusinessUnitCard.defaultProps = {
  // ButtonsContainer
  canEditProduct: false,
  onOpenEditDeploymentDialog: noop,
  onOpenDeleteDeploymentDialog: noop,
  onOpenLocalReferentsDialog: noop,
  localReferents: [],

  // ModulesListContainer
  modules: [],

  // DialogsContainer
  deployment: null,
  version: null,
  onCloseEditDeploymentDialog: noop,
  isEditDeploymentDialogOpen: false,
  onCloseDeleteDeploymentDialog: noop,
  isDeleteDeploymentDialogOpen: false,
  onCloseLocalReferentsDialog: noop,
  isLocalReferentsDialogOpen: false,

  // Card
  isInCharge: false,
  className: '',
  onClick: noop,
  shouldDisplayContainer: false,
  isInactive: false,
};

export default enhancer(BusinessUnitCard);
