import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import cn from 'classnames';

import { businessUnitShape } from 'shapes/businessUnit';
import BusinessUnitCard from '../card/BusinessUnitCard';

import withReferents from '../card/enhancers/withReferents';
import withDeployments from '../card/enhancers/withDeployments';
import classNames from './business-units-grid.module.scss';

const EnhancedBusinessUnitCard = compose(
  withDeployments,
  withReferents,
)(BusinessUnitCard);

const BusinessUnitsGrid = ({
  businessUnits,
  onClickBusinessUnit,
  className,
}) => (
  <div className={cn(className, classNames.grid)}>
    {businessUnits.map(businessUnit => (
      !businessUnit.isInactive && (
        <EnhancedBusinessUnitCard
          key={businessUnit.id}
          businessUnit={businessUnit}
          className={classNames.card}
          onClick={onClickBusinessUnit}
        />
      )))}
  </div>
);

BusinessUnitsGrid.displayName = 'BusinessUnitGrid';

BusinessUnitsGrid.propTypes = {
  businessUnits: PropTypes.arrayOf(businessUnitShape).isRequired,
  onClickBusinessUnit: PropTypes.func,
  className: PropTypes.string,
};

BusinessUnitsGrid.defaultProps = {
  onClickBusinessUnit: noop,
  className: null,
};

export default memo(BusinessUnitsGrid);
