import { compose } from 'recompose';
import { withRouter } from 'react-router';

import connect from 'react/hoc/connectProxy';
import {
  selectBusinessUnitsCount,
  selectBusinessUnitsIsLoading,
  selectBusinessUnitsListSortedByName,
} from 'redux/businessUnits/selectors';

import withOnClickBusinessUnit from './card/withOnClickBusinessUnit';

export default compose(
  withRouter,
  withOnClickBusinessUnit,
  connect(
    state => ({
      businessUnits: selectBusinessUnitsListSortedByName(state),
      totalCount: selectBusinessUnitsCount(state),
      loading: selectBusinessUnitsIsLoading(state),
    }),
  ),
);
