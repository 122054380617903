import React, { memo } from 'react';
import { Tooltip } from 'antd';
import messages from 'react/business/products/product.messages';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

const ProductOwnerTooltip = props => (
  <FormattedMessageChildren {...messages.BU_LEADER}>
    { message => <Tooltip title={message} {...props} /> }
  </FormattedMessageChildren>
);

ProductOwnerTooltip.displayName = 'ProductOwnerTooltip';

export default memo(ProductOwnerTooltip);
