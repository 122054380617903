import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import classNames from './titleHeader.module.scss';

const TitleHeader = ({ children, pageType }) => (
  <h1 className={cn(classNames.title, classNames[`title--${pageType}`])}>
    {children}
  </h1>
);

TitleHeader.displayName = 'TitleHeader';

TitleHeader.propTypes = {
  children: PropTypes.node.isRequired,
  pageType: PropTypes.string,
};

TitleHeader.defaultProps = {
  pageType: '',
};

export default memo(TitleHeader);
