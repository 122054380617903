import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { userShape } from 'shapes/user';
import { teamMemberShape } from 'shapes/product';

import connect from 'react/hoc/connectProxy';

import Avatar from 'react/generic/avatar/Avatar';
import UserName from 'react/generic/users/name/UserName';

import { selectBusinessUnitById } from 'redux/businessUnits/selectors';

import ContactButton from './button/ContactButton';
import classNames from './list.module.scss';

const enhancer = compose(
  connect(
    (state, props) => ({
      businessUnitName: selectBusinessUnitById(state, props.teamMember?.buReference)?.name,
    }),
  ),

  memo,
);

const ProductTeamMember = ({
  teamMember,
  users,
  businessUnitName,
  theme,
}) => (
  <li className={classNames.teamMemberElement}>
    <Avatar
      src={users[teamMember.externalId]?.avatar}
      className={classNames.avatar}
      alt=""
    />

    <div className={classNames.userName}>
      <UserName user={users[teamMember.externalId]} />
    </div>

    { teamMember.role
      && (
        <div className={classNames.userRole}>
          {teamMember.role.label}
          {businessUnitName && (
            <>
              <br />
              <span>{businessUnitName}</span>
            </>
          ) }
        </div>
      )}

    { (teamMember.role?.displayOnTop || teamMember.role?.isBuSuitable)
      && (
        <div className={classNames.contact}>
          { users[teamMember.externalId]?.email
            && (
              <ContactButton externalId={teamMember.externalId} theme={theme} />
            )}
        </div>
      )}
  </li>
);

ProductTeamMember.displayName = 'ProductTeamMember';

ProductTeamMember.propTypes = {
  teamMember: teamMemberShape.isRequired,
  // Users indexed by externalId
  users: PropTypes.objectOf(userShape),
  businessUnitName: PropTypes.string,
  theme: PropTypes.string,
};

ProductTeamMember.defaultProps = {
  users: {},
  businessUnitName: '',
  theme: '',
};

export default enhancer(ProductTeamMember);
