import React from 'react';
import PropTypes from 'prop-types';
import {
  compose, branch, renderComponent,
} from 'recompose';

import ProductOwnerRibbon from './ProductOwnerRibbon';
import ProductOwnerTooltip from '../tooltips/ProductOwnerTooltip';

const BusinessUnitRibbonSwitch = compose(
  branch(
    props => props.isInCharge,
    renderComponent(() => (
      <ProductOwnerTooltip placement="top">
        <ProductOwnerRibbon />
      </ProductOwnerTooltip>
    )),
  ),
)(() => null);

BusinessUnitRibbonSwitch.displayName = 'BusinessUnitRibbonSwitch';

BusinessUnitRibbonSwitch.propTypes = {
  isInCharge: PropTypes.bool,
};

BusinessUnitRibbonSwitch.defaultProps = {
  isInCharge: false,
};

export default BusinessUnitRibbonSwitch;
