import { defineMessages } from 'react-intl';

export default defineMessages({
  CONTACT_BUTTON_TOOLTIP: {
    id: 'product.businessUnit.localReferent.buttons.contact.tooltip',
    defaultMessage: 'Contact the Local Referents',
  },
  MODULES: {
    id: 'product.businessUnit.localReferent.modules.list.title',
    defaultMessage: 'Modules: ',
  },
});
