import PropTypes from 'prop-types';
import { externalImageShape } from './generic';

export const brandShape = PropTypes.shape({
  /** Identify the brand. */
  id: PropTypes.string.isRequired,
  /** URL of the logo. */
  logo: externalImageShape,
  /** Name of the brand. */
  name: PropTypes.string.isRequired,
});

export const countryShape = PropTypes.shape({
  /** Identify the country. */
  id: PropTypes.string.isRequired,
  /** URL of the flag. */
  flag: externalImageShape,
  /** Name of the country. */
  name: PropTypes.string.isRequired,
});

export const businessUnitShape = PropTypes.shape({
  /** Name. */
  name: PropTypes.string,
  /** Logo. */
  logo: externalImageShape,
  /** Identifier. */
  id: PropTypes.string,
  /** Brand data. */
  brand: brandShape.isRequired,
  /** Country data. */
  country: countryShape.isRequired,
  /** Code. */
  code: PropTypes.string,
});

export const businessUnitShapeNotReq = PropTypes.shape({
  /** Name. */
  name: PropTypes.string,
  /** Logo. */
  logo: externalImageShape,
  /** Identifier. */
  id: PropTypes.string,
  /** Brand data. */
  brand: brandShape,
  /** Country data. */
  country: countryShape,
  /** Code. */
  code: PropTypes.string,
});
