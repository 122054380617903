import PropTypes from 'prop-types';
import { cidShape } from './product';

export const deploymentShape = PropTypes.shape({
  id: PropTypes.string,
  nickname: PropTypes.string,
  cid: cidShape,
  buReference: PropTypes.string,
  version: PropTypes.string,
  modules: PropTypes.arrayOf(
    PropTypes.string,
  ),
});
