import { compose } from 'recompose';

import connect from 'react/hoc/connectProxy';
import { selectBrands } from 'redux/businessUnits/selectors';

export default compose(
  connect(
    state => ({
      values: selectBrands(state),
    }),
  ),
);
