import { withProps } from 'recompose';
import queryString from 'query-string';

import ROUTES from 'react/routes';
import { formatRoute } from 'react-router-named-routes';

export default withProps(({ history }) => ({
  onClickBusinessUnit: (businessUnitId) => {
    const qs = queryString.stringify({ businessUnitOwnerIds: [businessUnitId] });
    history.push(`${formatRoute(ROUTES.PRODUCTS)}?${qs}`);
  },
}));
