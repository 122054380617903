import { withPropsOnChange } from 'recompose';
import qs from 'query-string';
import { FILTERS } from 'react/business/facets/facets.constants';
import { ensureArray } from 'services/utils';

const filterBusinessUnits = (businessUnits, location) => {
  const query = qs.parse(location.search);

  // Build an array of all functions we'll use to filter businessUnits.
  // We'll make an AND combination of all of them.
  const filters = [];

  if (query[FILTERS.COUNTRY_IDS]) {
    filters.push(bu => ensureArray(query[FILTERS.COUNTRY_IDS]).includes(bu.country.id));
  }

  if (query[FILTERS.BRAND_IDS]) {
    filters.push(bu => ensureArray(query[FILTERS.BRAND_IDS]).includes(bu.brand.id));
  }

  return businessUnits.filter(bu => filters.every(filter => !!filter(bu)));
};

export default withPropsOnChange(
  ['location', 'businessUnits'],
  ({ location, businessUnits }) => ({
    businessUnits: filterBusinessUnits(businessUnits, location),
  }),
);
