import { withPropsOnChange } from 'recompose';
import queryString from 'query-string';
import { pick, omit } from 'lodash';

import { updateQueryString } from 'services/querystring';
import { ensureArray } from 'services/utils';
import { FILTERS } from '../facets.constants';

/**
 * Get filters value from the query string.
 * Also provides default values if needed.
 *
 * @param {object} location - React router location.
 * @returns {object} - Filter values.
 */
const getFiltersFromQueryString = ({ location }) => {
  const search = queryString.parse(location.search);
  const filters = pick(search, Object.values(FILTERS));

  return {
    ...filters,
    [FILTERS.COMMUNITY_IDS]: ensureArray(filters[FILTERS.COMMUNITY_IDS]),
    [FILTERS.DOMAIN_IDS]: ensureArray(filters[FILTERS.DOMAIN_IDS]),
    [FILTERS.BUSINESS_UNIT_IDS]: ensureArray(filters[FILTERS.BUSINESS_UNIT_IDS]),
    [FILTERS.COUNTRY_IDS]: ensureArray(filters[FILTERS.COUNTRY_IDS]),
    [FILTERS.BRAND_IDS]: ensureArray(filters[FILTERS.BRAND_IDS]),
    [FILTERS.STAGES_ID]: ensureArray(filters[FILTERS.STAGES_ID]),
    [FILTERS.MACRO_ACTIVITY_IDS]: ensureArray(filters[FILTERS.MACRO_ACTIVITY_IDS]),
    [FILTERS.ACTIVITY_IDS]: ensureArray(filters[FILTERS.ACTIVITY_IDS]),
    [FILTERS.ATOMIC_ACTIVITY_IDS]: ensureArray(filters[FILTERS.ATOMIC_ACTIVITY_IDS]),
    [FILTERS.ARCHITECTURE_BUILDING_BLOCK_IDS]:
      ensureArray(filters[FILTERS.ARCHITECTURE_BUILDING_BLOCK_IDS]),
    [FILTERS.ABB_COMMUNITY_IDS]: ensureArray(filters[FILTERS.ABB_COMMUNITY_IDS]),
    [FILTERS.IS_MAINTAINED_BY_PLATFORM]: ensureArray(filters[FILTERS.IS_MAINTAINED_BY_PLATFORM]),
    [FILTERS.SUBSET_IDS]: ensureArray(filters[FILTERS.SUBSET_IDS]),
    [FILTERS.SUBSET_COMMUNITY_IDS]: ensureArray(filters[FILTERS.SUBSET_COMMUNITY_IDS]),
  };
};

export default ({ baseRoute }) => withPropsOnChange(
  ['history', 'location'],
  ({ history, location }) => ({
    filters: getFiltersFromQueryString({ location }),
    onSetFilters: (filters) => {
      const qs = Object.keys(filters || {}).reduce(
        (prevQs, key) => {
          const value = filters[key];
          return updateQueryString(key, value, prevQs);
        },
        location.search,
      );
      history.push(`${baseRoute}?${qs}`);
    },

    /**
     * Reset the requested filters in the query string.
     *
     * @param {string[]} filters - Names of filters to reset. Defaults to all.
     */
    onResetFilters: (filters = []) => {
      const filtersToReset = filters.length ? filters : Object.values(FILTERS);
      const qs = omit(queryString.parse(location.search), filtersToReset);

      history.push(`${baseRoute}?${queryString.stringify(qs)}`);
    },
  }),
);
