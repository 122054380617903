import React from 'react';
import connect from 'react/hoc/connectProxy';
import { compose, lifecycle } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { formatRoute } from 'react-router-named-routes';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import ROUTES from 'react/routes';
import { NAVIGATION_SHORTCUTS_MESSAGES } from 'config/constants.messages';
import { getBusinessUnits } from 'redux/businessUnits/actions';
import { FILTERS_DISPLAY_TYPES } from 'react/business/facets/facets.constants';

import connectBusinessUnits from 'react/business/businessUnits/businessUnits.connect';
import BusinessUnitsGrid from 'react/business/businessUnits/grid/BusinessUnitsGrid';
import ApplicationLayout from 'react/business/layout/ApplicationLayout';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import withFiltersControllerInQueryString from 'react/business/facets/connect/withFiltersControllerInQueryString';
import FacetsContainerBusinessUnits from 'react/business/facets/FacetsContainerBusinessUnits';
import FiltersWithContentLayout from 'react/business/facets/layout/FiltersWithContentLayout';
import TitleHeader from 'react/business/layout/titleHeader/TitleHeader';
import withBusinessUnitsQueryStringFilter from './withBusinessUnitsQueryStringFilter';

import messages from './business-units-list.messages';
import classNames from './business-units-list.module.scss';

const ConnectedFacets = compose(
  withRouter,
  withFiltersControllerInQueryString({ baseRoute: formatRoute(ROUTES.BUSINESS_UNITS) }),
)(FacetsContainerBusinessUnits);

const ConnectedBusinessUnitsGrid = compose(
  // Get BUs from store, handle click...
  connectBusinessUnits,

  // Add the actions in the props.
  connect(
    null,
    { getBusinessUnits },
  ),

  // Trigger the load on mount.
  lifecycle({
    /** Did mount. */
    componentDidMount() {
      this.props.getBusinessUnits();
    },
  }),

  // Filters BU according to querystring.
  withBusinessUnitsQueryStringFilter,
)(BusinessUnitsGrid);

const BusinessUnitsList = () => (
  <ApplicationLayout
    mainClassName={classNames.main}
  >
    <FormattedMessageChildren {...messages.TITLE}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>
    <TitleHeader>
      <FormattedMessage {...NAVIGATION_SHORTCUTS_MESSAGES.BUSINESS_UNITS} />
    </TitleHeader>
    <FiltersWithContentLayout
      filtersComponent={ConnectedFacets}
      content={<ConnectedBusinessUnitsGrid />}
      type={FILTERS_DISPLAY_TYPES.FILTERS_TOP}
    />
  </ApplicationLayout>
);

BusinessUnitsList.displayName = 'BusinessUnitsList';

export default BusinessUnitsList;
