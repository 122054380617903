import { compose, withPropsOnChange } from 'recompose';

import connect from 'react/hoc/connectProxy';

import withDialogState from 'react/generic/dialog/withDialogState';

import { selectModulesFromProduct } from 'redux/products/selectors';

export default compose(
  withDialogState('EditDeployment'),
  withDialogState('DeleteDeployment'),

  connect(
    (state, props) => ({
      productModules: selectModulesFromProduct(state, props),
    }),
  ),

  withPropsOnChange(
    ['deployment', 'productModules'],
    ({ deployment, productModules }) => ({
      modules: productModules.filter(module => (deployment?.modules || []).includes(module.id)),
    }),
  ),
);
